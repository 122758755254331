import { subDays, addDays } from "date-fns";

import oldSpaceImage from "../images/old-space.jpeg";
import floorTrioImage from "../images/trio-on-floor.jpeg";
import combinedPandemicImage from "../images/combined-pandemic.jpeg";
import Configuration from "../Configuration";

export const cohortSchedule = [
  {
    id: 34,
    cohort: "Boston #34",
    enrollmentEnds: addDays(new Date(2021, 7, 5), 10).toISOString(),
    ignitionBegins: new Date(2021, 7, 5).toISOString(),
    preflightBegins: subDays(new Date(2021, 7, 5), 21).toISOString(),
    cohortBegins: addDays(new Date(2021, 7, 5), 17).toISOString(),
    cohortEnds: addDays(new Date(2021, 7, 5), 67).toISOString(),
    buttonURL: Configuration.applicationBaseUrl,
    buttonText: "Apply Today",
  },
  {
    id: 35,
    cohort: "Boston #35",
    enrollmentEnds: addDays(new Date(2021, 10, 4), 10).toISOString(),
    ignitionBegins: subDays(new Date(2021, 10, 4), 56).toISOString(),
    preflightBegins: subDays(new Date(2021, 10, 4), 21).toISOString(),
    cohortBegins: addDays(new Date(2021, 10, 4), 24).toISOString(),
    cohortEnds: addDays(new Date(2021, 10, 4), 74).toISOString(),
    buttonURL: Configuration.applicationBaseUrl,
    buttonText: "Apply Today",
  },
];

export const interviewContent = [
  {
    id: "applicationSubmission",
    header: "Application Submission",
    body: "Select a date and time to conduct your 1 hour Zoom video call interview.",
  },
  {
    id: "personalInterview",
    header: "1:1 Personal Interview",
    body:
      "We’ll spend an hour getting to know one another and solving problems collaboratively. Interview agenda here",
  },
  {
    id: "programAcceptance",
    header: "Program Acceptance",
    body: "We can usually provide a decision within a few days following your interview.",
  },
];

// export const courseContent = [
//   {
//     imageUrl: "https://placekitten.com/408/287",
//     header: "Learn the right things, in the right order",
//     body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia."
//   },
//   {
//     imageUrl: "https://placekitten.com/408/287",
//     header: "Learn the right things, in the right order",
//     body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia."
//   },
//   {
//     imageUrl: "https://placekitten.com/408/287",
//     header: "Learn the right things, in the right order",
//     body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia."
//   },
//   {
//     imageUrl: "https://placekitten.com/408/287",
//     header: "Learn the right things, in the right order",
//     body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia."
//   },
//   {
//     imageUrl: "https://placekitten.com/408/287",
//     header: "Learn the right things, in the right order",
//     body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia."
//   },
//   {
//     imageUrl: "https://placekitten.com/408/287",
//     header: "Learn the right things, in the right order",
//     body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia."
//   }
// ];

export const courseColumns = 3;
export const interviewColumns = 3;
