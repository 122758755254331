import * as React from "react";
import { graphql } from "gatsby";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

import Configuration from "../Configuration";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "@launchacademy/voyager/dist/css/theme/quote/quote.css";

import { interviewContent, codedQuestions, interviewColumns } from "../pageData/admissionsData";
import { cohortSchedule } from "../pageData/programPageData";
import {
  Button,
  Quote,
  CourseScheduleList,
  CardList,
  SimpleBanner,
  BannerTitle,
  MobileDeviceTile,
  Module,
  ModuleLearningObjective,
  FaqList,
  FeatureList,
  FeatureListItem,
  AccentCircle,
} from "@launchacademy/voyager";

import "../css/tuition.css";

import "./css/admissions.css";

import buildImage from "../services/buildImage";
import SyllabusModalButton from "../components/syllabus/SyllabusModalButton";
import BookInfoCallButton from "../components/infoCall/BookInfoCallButton";

import "../css/accent-images/admissions.css";
import { faChevronRight, faInfoCircle, faEye, faPhone } from "@fortawesome/free-solid-svg-icons";

const Admissions = ({ data, location }) => {
  const interviewContentWithImages = interviewContent.map((content) => {
    return { ...content, Image: buildImage(data[content.id]) };
  });

  return (
    <Layout>
      <Seo
        description="Learn about Launch Academy's admissions process and how to get accepted to our software development program"
        pathname={location.pathname}
        title="Admissions: Getting Accepted to Launch Academy's Immersive Program"
      />
      <section className="hero pb-20">
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage
              image={getImage(data.nickBigWhiteboard)}
              className="hero__image"
              alt="Teacher writing on whiteboard"
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h2 className="hero__image_subtitle">Launch your career.</h2>
          <h1 className="hero__image_title">
            Everything you need to know to be successful at Launch
          </h1>
          <div className="hero__button-container">
            <BookInfoCallButton
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button"
              location={location}
              size="lg"
            />
          </div>
          <div className="hero__button-container">
            <SyllabusModalButton
              location={location}
              size="lg"
              icon={faEye}
              className="hero__button button_secondary"
              text="Get the Syllabus"
            />
          </div>
          <Quote
            quote="I highly recommend Launch Academy to anyone who is thinking about switching to software development. Launch Academy found a great formula for success."
            name="Mo Zhu"
            company="3Play Media"
          />
        </div>
      </section>
      <h2 className="text-center">Upcoming course schedule</h2>
      <CourseScheduleList cohortSchedule={cohortSchedule} />
      <div className="feature-list pt-12">
        <FeatureList
          title="We help people who want to design their own future, and are ready to work hard to get there."
          subtitle="Students from all backgrounds, work experiences, and walks of life get their start at Launch. There is no “right” person for the job -- one of the many things we love about this industry.t. Here are a few things that succesful students have in common."
          prefixContent={
            <>
              <GatsbyImage
                image={getImage(data.studentsCollaboratingWithTeacher)}
                className="feature-list__accent-image__top-left"
                alt="students collaborating with teacher"
              />
              <div className="absolute" style={{ top: "40rem" }}>
                <GatsbyImage
                  image={getImage(data.briannaSmallWhiteboard)}
                  className="hidden md:block z-10"
                  alt="teacher writing on standing whiteboard"
                />
                <GatsbyImage
                  image={getImage(data.twoStudentsTalking)}
                  className="hidden md:block opacity-30 -top-4 z-0"
                  alt="two students talking at a computer"
                />
              </div>
            </>
          }
        >
          <FeatureListItem
            title="Passion for building things and solving puzzles"
            description="A commitment to learning by doing through a proven problem solving framework sets you up for success on the job."
            icon={faInfoCircle}
          />
          <FeatureListItem
            title="Ability to play well with others"
            description="Software engineering is a team sport. Learning collaboratively mirrors the real world and enhances the learning experience."
            icon={faInfoCircle}
          />
          <FeatureListItem
            title="Insatiable curiosity and love for learning"
            description="A deep interest in understanding how and why things work is an essential trait for lifelong learning and personal growth."
            icon={faInfoCircle}
          />
          <FeatureListItem
            title="Commitment to building a new career"
            description="Investing in your future self will result in a rewarding professional life."
            icon={faInfoCircle}
          />
          <FeatureListItem
            title="Grit, grit, and more grit"
            description="Having a positive growth mindset to persevere towards a long term goal will power you through the learning journey."
            icon={faInfoCircle}
          />
        </FeatureList>
      </div>
      <section className="bg-secondary-brand">
        <div className="container grid grid-cols-12 py-24">
          <div className="footer-container__text col-span-8 col-start-3 relative">
            <AccentCircle className="-top-16 -left-16" width="32" />
            <h2 className="z-10 relative">
              Success at Launch is not about what you know, it’s about your willingness to learn and
              commit to changing your future. No matter where you start, Launch provides a
              structured and proven process to becoming a professional developer.
            </h2>
          </div>
        </div>
      </section>
      <SimpleBanner
        type="prose"
        BackgroundImage={buildImage(data.collaboratingBackground)}
        altText="Two students working together in sitting area"
      >
        <div className="font-headline pl-10 pr-20">
          <BannerTitle>
            Launch Academy supports you throughout your entire career, helping you advance and excel
            in the role long after you land your first job.
          </BannerTitle>
        </div>
      </SimpleBanner>
      <div className="module bg-gray-dorian">
        <Module
          title="Prepare to launch your new career"
          detail="What you'll need to get ready"
          description="Preparing for any interview is critical to your success.  During the program, we’ll teach you how to get ready for job interviews.  The admissions interview is your opportunity to prove to us that you are up for the challenge!"
          Image={buildImage(data.prepareToLaunch)}
          altText="Students collaborating in front of laptop"
        >
          <ModuleLearningObjective objectiveDescription="Apply - Provide some basic details and schedule your 1-hour zoom interview." />
          <ModuleLearningObjective objectiveDescription="Prepare - During your interview, you’ll spend 5 minutes teaching us about a topic of interest." />
          <ModuleLearningObjective objectiveDescription="Be Yourself - It’s interview time!  We can’t wait to get to know you and share more about the program.  Here’s the agenda." />
        </Module>
      </div>
      <div className="container mt-20 mx-4 constrained-container">
        <div>
          <h2>
            What to expect from your
            <br /> interview process
          </h2>
        </div>
        <div className="banner_no-photo__container my-8">
          <BookInfoCallButton
            text="Schedule an Info Call"
            icon={faPhone}
            className="hero__button pr-3 mr-8"
            secondary
            location={location}
            size="lg"
          />
        </div>
      </div>
      <section className="card-list-container relative constrained-container mb-20">
        <GatsbyImage
          image={getImage(data.studentsAroundConferenceTable)}
          className="banner__accent-image banner__accent-image_top-right"
          studentsAroundConferenceTable
          alt="students conversing around a conference table"
        />
        <CardList content={interviewContentWithImages} numberOfColumns={interviewColumns} />

        <GatsbyImage
          image={getImage(data.studentDeskSetup)}
          className="banner__accent-image_bottom-left"
          alt="view of a student's desk"
        />
      </section>
      <section className="bg-secondary-brand">
        <div className="constrained-container">
          <div className="hero">
            <div className="hero__supporting-content">
              <h2 className="hero__image_title py-10">
                “I had doubled my earning potential in less than a year. That's one hell of a ROI.”
              </h2>
              <div className="md:flex">
                <GatsbyImage
                  image={getImage(data.davidGarber)}
                  className="rounded-full"
                  alt="David Garber: Launch Academy Alumnus"
                />
                <div className="md:ml-4 md:flex-grow">
                  <h6 className="font-bold font-headline leading-snug uppercase">David Garber</h6>
                  <h5 className="font-text text-base uppercase">Comcast</h5>
                </div>
              </div>
            </div>
            <div className="hero__image-column mt-4 lg:pl-32 relative">
              <GatsbyImage
                image={getImage(data.fourStudentsCollaborating)}
                className="featured-project__accent-image"
                alt="three students looking at a monitor"
              />
              <MobileDeviceTile
                Image={buildImage(data.iPhoneScreenImage)}
                altText="iPhone screen"
              />
              <h5 className="font-text text-base uppercase text-left pt-5">
                Student<br></br>Project
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="tuition-plan py-20 constrained-container">
        <div className="tuition-plan__header-container">
          <div className="tuition-plan__title pb-8">
            <h2>Flexible tuition plans to fit your needs</h2>
          </div>
          <div className="tuition-plan__subtitle">
            <h3>
              Investing in your future-self should be one of the highest returns one can earn. In
              addition, financing your tuition should not require a law degree to understand the
              terms. That is why we have partnered with Ascent, who helps Launch Academy students
              pay tuition and living expenses through straightforward loans.
            </h3>
          </div>
        </div>
        <div className="tuition-plan__grid">
          <div className="tuition-plan__item">
            <h4 className="tuition-plan__item-title">Scholarships</h4>
            <p className="tuition-plan__item-description">
              We offer $500 diversity & military scholarships to level the playing field. Upon
              completion, you receive a $1,000 scholarship. Let’s ensure you get off to a great
              start before the program begins!
            </p>
          </div>
          <div className="tuition-plan__item">
            <h4 className="tuition-plan__item-title">Upfront payment</h4>
            <p className="tuition-plan__item-description">
              Pay your $17,500 tuition in full in week 2 of the program. We want to ensure you have
              validated your love for coding and confirmed that we will serve as the optimal vehicle
              to get you there prior to making a life changing commitment.
            </p>
          </div>
          <div className="tuition-plan__item">
            <h4 className="tuition-plan__item-title">Deferred Payment via Ascent</h4>
            <p className="tuition-plan__item-description">
              Straightforward loans and student-friendly payment options. Make no payments until
              three months after graduation. Budget manageable payments thereafter over 3-5 years.
              Obtain an up-to $6,000 cost of living stipend. Access Ascent’s tuition calculator
              here.
            </p>
            <div className="tuition-plan__item-button-container">
              <Button
                text="Learn More"
                to="https://partner.ascentfunding.com/launchacademy/"
                size="lg"
                secondary
                className="tuition-plan__item-button"
              />
            </div>
          </div>
          <div className="tuition-plan__item">
            <h4 className="tuition-plan__item-title">
              Unemployment Assistance via Massachusetts TOP
            </h4>
            <p className="tuition-plan__item-description">
              As an approved training provider, Mass residents on unemployment can extend benefits
              through the completion of the course via the Training Opportunity Program. This may be
              combined with scholarships and Ascent’s $6,000 cost of living stipend.
            </p>
            <div className="tuition-plan__item-button-container">
              <Button
                text="Learn More"
                to="https://www.mass.gov/how-to/apply-for-the-training-opportunities-program"
                size="lg"
                secondary
                className="tuition-plan__item-button"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="faq-list">
        <h2>
          Have questions about tuition? Our admissions team can help to ensure our program is
          accessible for you.
        </h2>

        <FaqList
          questions={(data.faqSet?.frequently_asked_questions || []).map((question) => {
            return {
              title: question.question,
              description: <ReactMarkdown>{question.answer}</ReactMarkdown>,
              id: question.id,
            };
          })}
        />
      </div>
      <section className="bg-secondary-brand">
        <div className="constrained-container hero">
          <div className="hero__supporting-content py-10">
            <h2 className="hero__image_title pb-6">
              Our multi-phased approach to learning provides students with safety nets.
            </h2>
            <div className="hero__button-container">
              <BookInfoCallButton
                text="Schedule an Info Call"
                icon={faPhone}
                className="banner__button mr-4 mt-4"
                location={location}
                size="lg"
              />
              <SyllabusModalButton
                location={location}
                text="Get the Syllabus"
                icon={faChevronRight}
                className="banner__button button_secondary z-50 relative mt-4"
                size="lg"
              />
            </div>
          </div>
          <div className="hero__image-column py-16">
            <div className="hero__image-container relative">
              <GatsbyImage
                image={getImage(data.nickTeaching)}
                className="hero__accent-top-right"
                alt="three students working together on the floor"
              />

              <GatsbyImage
                image={getImage(data.threeStudentsLookingAtMonitor)}
                className="hero__image hero__image_sm z-10 shadow-md pb-8"
                alt="three students working together on the floor"
              />
              <GatsbyImage
                image={getImage(data.studentWorkstationWithMultipleMonitors)}
                className="hero__accent-bottom-left"
                alt="student workstation with multiple monitors"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Admissions;

export const pageQuery = graphql`
  query {
    nickBigWhiteboard: file(relativePath: { regex: "/admissions-page/nickBigWhiteboard.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    briannaSmallWhiteboard: file(
      relativePath: { regex: "/admissions-page/briannaSmallWhiteboard.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 390
          height: 460
          transformOptions: { cropFocus: WEST }
        )
      }
    }
    davidGarber: file(relativePath: { regex: "/admissions-page/davidGarber.jpg/" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: { cropFocus: CENTER, fit: FILL }, width: 64, height: 64)
      }
    }
    nickTeaching: file(relativePath: { regex: "/nickTeachingCode/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 400
          height: 280
          transformOptions: { cropFocus: EAST }
        )
      }
    }
    studentsCollaboratingWithTeacher: file(
      relativePath: { regex: "/admissions-page/studentsCollaboratingWithTeacher.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 240, height: 270)
      }
    }
    twoStudentsTalking: file(relativePath: { regex: "/admissions-page/twoStudentsTalking.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 730, height: 495)
      }
    }
    collaboratingBackground: file(
      relativePath: { regex: "/admissions-page/collaboratingBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    prepareToLaunch: file(relativePath: { regex: "/admissions-page/prepareToLaunch.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630], height: 630)
      }
    }
    applicationSubmission: file(
      relativePath: { regex: "/admissions-page/applicationSubmission.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    personalInterview: file(
      relativePath: { regex: "/admissions-page/threeStudentsLookingAtMonitor.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    programAcceptance: file(relativePath: { regex: "/admissions-page/programAcceptance.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    studentsAroundConferenceTable: file(
      relativePath: { regex: "/admissions-page/studentsAroundConferenceTable.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 180, height: 250)
      }
    }
    studentDeskSetup: file(relativePath: { regex: "/admissions-page/studentDeskSetup.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 280
          height: 400
          transformOptions: { cropFocus: EAST }
        )
      }
    }
    iPhoneScreenImage: file(relativePath: { regex: "/campgroundsMobile.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 230)
      }
    }
    profileImageOne: file(relativePath: { regex: "/admissions-page/profileImageOne.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    threeStudentsLookingAtMonitor: file(
      relativePath: { regex: "/admissions-page/threeStudentsLookingAtMonitor.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    fourStudentsCollaborating: file(
      relativePath: { regex: "/admissions-page/fourStudentsCollaborating.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    studentWorkstationWithMultipleMonitors: file(
      relativePath: { regex: "/admissions-page/studentDeskSetup.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 220, height: 260)
      }
    }
    faqSet: strapiFrequentlyAskedQuestionSet(name: { eq: "admissions" }) {
      frequently_asked_questions {
        id
        answer
        question
      }
    }
  }
`;
